<template>
  <div id="couriers-view">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-show="!isLoading">
      <vs-table hoverFlat ref="table" :data="filteredItems" search stripe pagination :max-items="itemsPerPage">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-no-wrap">
            <div class="flex flex-wrap-reverse items-center">
              <router-link v-if="$acl.check('superadmin')" :to="{name: 'courierCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
              </router-link>
            </div>
          </div>
          <div>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mx-2">{{ $t('sort') }}: </span>
                <span class="capitalize mr-2">{{$t(current)}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="current='all'">
                  <span>{{$t('all')}}</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="current='express'">
                  <span>{{$t('express')}}</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="current='market'">
                  <span>{{$t('market')}}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ carriers.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : carriers.length }} of {{ queriedItems }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage=4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
        <template slot="thead">
          <vs-th class="pr-0" sort-key="isExpress">#</vs-th>
          <vs-th class="pl-0" sort-key="code">{{ $t('fields.code') }}</vs-th>
          <vs-th class="pl-0" sort-key="name">{{ $t('fields.name') }}</vs-th>
          <vs-th class="hidden lg:table-cell" sort-key="phone">{{ $t('fields.phone') }}</vs-th>
          <vs-th class="hidden lg:table-cell" sort-key="email">{{ $t('fields.email') }}</vs-th>
          <vs-th sort-key="isBlocked">{{ $t('fields.active') }}?</vs-th>
          <vs-th>{{ $t('fields.cash') }}</vs-th>
          <vs-th sort-key="tasks_all">{{ $t('fields.tasks') }}</vs-th>
          <vs-th><span class="ml-auto">{{ $t('fields.actions') }}</span></vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
            <vs-td class="pr-0">
              <feather-icon v-if="data[indextr].isExpress" icon="WindIcon" svgClasses="w-5 h-5 text-primary" style="transform: rotate(180deg)" />
              <feather-icon v-if="data[indextr].isExpress" icon="TruckIcon" svgClasses="w-5 h-5 text-primary" />
            </vs-td>
            <vs-td class="pl-0" :data="data[indextr].code">
              {{ data[indextr].id }} | {{ data[indextr].code }}
            </vs-td>
            <vs-td class="pl-0" :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>
            <vs-td :data="data[indextr].phone" class="hidden lg:table-cell">
              {{ data[indextr].phone }}
            </vs-td>
            <vs-td :data="data[indextr].email" class="hidden lg:table-cell">
              {{ data[indextr].email }}
            </vs-td>
            <vs-td :data="data[indextr].isBlocked">
              <vs-chip :color="!tr.isBlocked ? 'primary' : 'danger'">
                <feather-icon v-if="!tr.isBlocked" icon="CheckIcon" svgClasses="w-5 h-5" />
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].cash">
              {{ data[indextr].cash }}
            </vs-td>
            <vs-td :data="data[indextr].tasks_all">
              {{ data[indextr].tasks_today }}/<strong>{{ data[indextr].tasks_all }}</strong>
            </vs-td>
            <vs-td class="whitespace-no-wrap text-right">
              <feather-icon icon="EyeIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]" class="" :title="$t('details')" @click.stop="showData(tr.uuid)" />
              <template v-if="$acl.check('superadmin')" >
                <feather-icon icon="EditIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" :title="$t('edit')" @click.stop="editData(tr.uuid)" />
                <!-- <feather-icon v-if="!tr.isBlocked" icon="LockIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" title="Change courier status" @click.stop="changeStatus(tr.uuid, tr.isBlocked)" /> -->
                <!-- <feather-icon v-else icon="UnlockIcon" :svgClasses="[!tr.isBlocked ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" title="Change courier status" @click.stop="changeStatus(tr.uuid, tr.isBlocked)" /> -->
              </template>
              <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid)" /> -->
            </vs-td>
          </vs-tr>
        </template>

      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  data () {
    return {
      carriers: [],
      itemsPerPage: 15,
      isMounted: false,
      isLoading: true,
      current: 'all'
    }
  },
  computed: {
    filteredItems () {
      switch (this.current) {
      case ('all'):
        return this.carriers
      case ('express'):
        return this.carriers.filter((item) => item.isExpress)
      case ('market'):
        return this.carriers.filter((item) => !item.isExpress)
      }
    },
    currentPage () {
      if (!this.isLoading) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () { return this.$refs.table ? this.$refs.table.queriedResults.length : this.carriers.length },
    getName () {
      return (cId) => {
        const cat =  this.carriers.find(cat => cat.id === cId)
        if (cat) {
          return cat[`name_${this.$i18n.locale}`]
        }
        return '---'
      }
    }
  },
  async created () {
    await this.fetchData()
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    async fetchData () {
      await this.$http.get('/carriers').then(response => {
        this.isLoading = false
        this.carriers = response.data.data
      }).catch(err => {
        this.isLoading = false
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    deleteData (id) {
      this.$http.delete(`/carriers/delete/${id}`).then(() => {
        this.$vs.notify({
          title: 'Success',
          text: 'Category successfully deleted!',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
        this.fetchData()
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    editData (id) { this.$router.push(`/couriers/${id}/edit`) },
    showData (id) { this.$router.push(`/couriers/${id}`) }
    // async changeStatus (id, status) {
    //   await this.$http.patch(`/carriers/edit/${id}`, {
    //     isBlocked: !status
    //   }).then(response => {
    //     console.log(response)
    //     this.fetchData()
    //     this.$vs.notify({
    //       title: 'Success',
    //       text: 'Courier status successfully changed!',
    //       iconPack: 'feather',
    //       icon: 'icon-check-circle',
    //       color: 'primary'
    //     })
    //   }).catch(err => {
    //     this.$vs.notify({
    //       title: 'Error',
    //       text: err.message,
    //       iconPack: 'feather',
    //       icon: 'icon-alert-circle',
    //       color: 'danger'
    //     })
    //   })
    //   // this.$store.dispatch('dataList/removeItem', id).catch(err => { console.error(err) })
    // }
  }
}
</script>

<style lang="scss">
#couriers-view {
  .vs-con-table {
    .img {
      background-color: #cdcdcd;
    }
    .product-name {
      max-width: 23rem;
    }
    .vs-table--tr {
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
}
</style>